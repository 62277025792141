import React, { useState, useEffect } from 'react';
import { request_explor } from '../common/web';
import { useMyContext } from '../component/MyContext';
import { useNavigate } from 'react-router-dom';
import '../styles/mystyle.css'

function Login() {

  const { admn_set } = useMyContext();
  const [user, user_set] = useState('');
  const [pass, pass_set] = useState('');
  const [eror, eror_set] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
  }, []);

  async function handleLogin(e) {
    e.preventDefault();
    if (!user || !pass) {
      eror_set('Tous les champs sont requis');
    } else {
      eror_set('');
      await check_user();
    }
  };

  async function check_user() {
    var data, error;
    const query = { "code": 500, "user": user, "pass": pass };
    [data, error] = await request_explor(query);
    if (error !== "") {
      alert(error);
      return;
    }
    if (data["pass"] === pass) {
      admn_set(0);
    };
    if (data["admn"] === '1') {
      admn_set(1);
    };
    navigate('/home');
  }

  return (
    <div style={{ Direction: 'ltr' }}>
      <div style={styles.container} >
        <form onSubmit={handleLogin} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="mail"> email </label>
            <input
              id="mail"
              type="email"
              value={user}
              onChange={(e) => user_set(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="pass"> password </label>
            <input
              id="pass"
              type="password"
              value={pass}
              onChange={(e) => pass_set(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          {eror && <p style={styles.error}>{eror}</p>}
          <button type="submit" style={styles.button}>connect</button>
        </form>
      </div>
    </div>
  );
}

// Styles simples en ligne
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  formGroup: {
    marginBottom: '12px',
    textAlign: 'left',
    flexDirection: 'row',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '16px',
    textAlign: 'left',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
  },
};

export default Login;
