import React, { useState, useEffect } from 'react';
import { request_explor } from '../common/web';
import GoBack from '../component/GoBack';
import TableFromJasonFor from '../component/TableFromJasonFor';

const Duaa = () => {
  const [page, page_set] = useState("");
  const [duaa, duaa_set] = useState([]);
  

  useEffect(() => { request_duaa(); }, []);

  useEffect(() => { display_duaa(duaa);}, [duaa]); 

  async function request_duaa() {
    var data, error;
    [data, error] = await request_explor({ "code": 300 })
    if (error !== "") {
      alert(error)
      return
    }
    duaa_set(data);
  }

  function display_duaa(duaa) {
    const headers = ['', 'الدعــاء', 'المصدر'];
    page_set(<TableFromJasonFor head={headers} data={duaa} />);
  }

  return (
    <>
      <div className='col'>
        <br />
        <GoBack />
        <div> {page} </div>
      </div>
    </>
  )
};

export default Duaa;