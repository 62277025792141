import React from 'react';
import { find } from '../common/mytools'; 

const SearchAyat = ({ head, ayat, kalimat }) => {
  const heads = [];
  for (let i = 0; i <head.length; i++) {
    heads.push(<th key={i}>{head[i]}</th>);
  }

  const rows = [];
  for (let i = 0; i <ayat.length; i++) {
    const words = ayat[i].nass.split(" ");

    var aya = [];
    for (let j = 0; j <words.length; j++) {
      if (find(kalimat, words[j]))
        aya.push(<span key={2} className='highlight'>{words[j]}</span>, " ");
      else
        aya.push(words[j], " ");
    }

    var cols = []
    cols.push(<td key={0}>{ayat[i].sura}</td>);
    cols.push(<td key={1}>{ayat[i].aaya}</td>);
    cols.push(<td key={2}>{aya}</td>);

    rows.push(<tr key={i}>{cols}</tr>);
  }

  return (
    <table>
      <thead><tr>{heads}</tr></thead>
      <tbody>{rows}</tbody>
    </table>
  );

}
export default SearchAyat;
