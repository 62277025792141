import React from 'react'
import '../../styles/mystyle.css'

export function get_font(i) {
  const fonts = [
    "KFGQPC HafsEx1 Uthmanic Script",
    "KFGQPC WARSH Uthmanic Script",
    "KFGQPC BAZZI Uthmanic Script",
    "KFGQPC DOORI Uthmanic Script",
    "KFGQPC QALOON Uthmanic Script",
    "KFGQPC QUMBUL Uthmanic Script",
    "KFGQPC SHOUBA Uthmanic Script",
    "KFGQPC SOOSI Uthmanic Script",
  ];
  if (i >= 1 && i <= 8)
    return fonts[i - 1];
  else return ("???");
}

export function RiwayaSelector({ selectedRiwaya, setSelectedRiwaya, setSelectedFont }) {
  const handleRiwayaChange = (event) => {
    event.preventDefault();
    setSelectedRiwaya(event.target.value);
  };

  return (
    <div className='row'>
      <label htmlFor="fontSelector">الرواية   </label>
      <select id="riwayaSelector" value={selectedRiwaya} onChange={handleRiwayaChange}
        style={{ width: 50, fontSize: 10, fontWeight: 'bold' }}>
        {/* <option value="00"> الرواية </option> */}
        <option value="1">حفص </option>
        <option value="2">ورش </option>
        <option value="3">بزّي </option>
        <option value="4">دوري </option>
        <option value="5">قالون</option>
        <option value="6">قنبل </option>
        <option value="7">شعبة </option>
        <option value="8">سوسي </option>
      </select>
    </div>
  );
};

export function FontSelector({ show, selectedFont, setSelectedFont }) {

  const handleFontChange = (event) => {
    event.preventDefault();
    setSelectedFont(event.target.value);
  };
  if (!show) return null;
  return (
    <div className='row'>
      <label htmlFor="fontSelector"> الخـــط   </label>
      <select id="fontSelector" value={selectedFont} onChange={handleFontChange}
        style={{ width: 70, fontSize: 10, fontWeight: 'bold' }}>
        <option value="1">HafsEx1</option>
        <option value="2">WARSH  </option>
        <option value="3">BAZZI  </option>
        <option value="4">DOORI  </option>
        <option value="5">QALOON </option>
        <option value="6">QUMBUL </option>
        <option value="7">SHOUBA </option>
        <option value="8">SOOSI  </option>
      </select>
    </div>
  );

};