import Cookies from 'js-cookie';
function set_cookie (name, value) {
  Cookies.set(name, value, { expires: 1000 });
};

function get_cookie (name) {
  const value = Cookies.get(name);
  return value;
};

/*const remove_cookie = (name) => {
  Cookies.remove(name);
};*/

export function load_cookies(rwya_set, sura_set, aaya_set, sfha_set) {
  var ck;
  ck = get_cookie('rwya'); /*if (!ck) ck = 1; */ rwya_set(ck);
  ck = get_cookie('sura'); /*if (!ck) ck = 1; */ sura_set(ck);
  ck = get_cookie('aaya'); /*if (!ck) ck = 1; */ aaya_set(ck);
  ck = get_cookie('sfha'); /*if (!ck) ck = 1; */ sfha_set(ck);
  return null;
}

export function save_cookies(rwya, sura, aaya, sfha) {
  set_cookie('rwya' , rwya);
  set_cookie('sura' , sura);
  set_cookie('aaya' , aaya);
  set_cookie('sfha' , sfha);
}

/* export function load_safha(sfha_set) {
  var ck;
  ck = get_cookie('sfha');
  if (ck === undefined) ck = 1;
  sfha_set(ck);
  //alert(`loading safha:${ck}`);
} */

export function load_safha() {
  var ck;
  ck = get_cookie('sfha');
  if (ck === undefined) ck = 1;
  //sfha_set(ck);
  console.log(`loading safha:${ck}`);
  return ck;
}

export function save_safha(sfha) {
  set_cookie('sfha' , sfha);
  console.log(`saving safha:${sfha}`);
}
