import React from 'react';
import '../styles/fontstyle.css'
const FontSelector10 = ({ selectedFont, onFontChange }) => {

   const handleFontChange = (event) => {
      onFontChange(event.target.value);
   };

   return (
      <div>
         {/* <label htmlFor="fontSelector">Select a font: </label> */}
         <select id="fontSelector" value={selectedFont} onChange={handleFontChange}
            style={{ width: 100, fontSize: 10 }}>
            <option value="الخـــط"> الخـــط </option>
            <option value="KFGQPC Uthmanic Script HAFS">Uthmanic HAFS</option>
            <option value="KFGQPC WARSH Uthmanic Script">Uthmanic  WARSH</option>
            <option value="QURAN MADINA">MADINA</option>
            <option value="Arabic Typesetting">Arabic Typesetting</option>
            <option value="Traditional Arabic">Traditional Arabic</option>
            {/* 
            <option value="Simplified Arabic">Simplified Arabic</option>
            <option value="Andalus">Andalus</option>
            <option value="AB AlBayan">AB AlBayan</option>
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
             */}
         </select>
      </div>
   );
};

export default FontSelector10;


/*
   font-family:
 **  "AB AlBayan",
   "DejaVu Serif", 
   "STFangsong",
   "STHeiti",
   "STKaiti",
   "STSong",
   "AB Geeza",
   "AB Kufi",
   "Aldhabi",
 **  "Andalus",
   "Sakkal Majalla",
 **  "Simplified Arabic",
 **  "Traditional Arabic",
 **  "Arabic Typesetting",
   "Urdu Typesetting",
   "Droid Naskh",
   "Droid Kufi",
   "Roboto",
   "Geeza Pro"
   "Nadeem",
   "Al Bayan",
   "DecoType Naskh"

   "Arial";
   "Tahoma",
   "Times New Roman",
*/
