import ReactDOM from "react-dom/client";
import React from "react";
import App from './App';
import MyContextProvider from './component/MyContext'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
         <MyContextProvider>
            <App />
         </MyContextProvider>
   </React.StrictMode>,
);
