import { React } from 'react';
import { useMyContext } from '../component/MyContext';
import { useState } from 'react';
import '../styles/mystyle.css';

function Safahat({ show, onClose, children }) {
  const { sfha, sfha_set } = useMyContext();

  function InputNumber({ initial, setValue }) {
    const [nombre, setNombre] = useState(initial);
    const handleChange = (event) => {
      setNombre(event.target.value);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      setValue(nombre);
      onClose();
    };

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="nombre"> الصفحة </label>
          <input
            type="number"
            id="nombre"
            value={nombre}
            onChange={handleChange}
            style={{ width: '50px' }}
          />
          <button type="submit">{"<<>>"}</button>
        </form>
      </div>
    );
  }
  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div className='col'>
          <InputNumber initial={sfha} setValue={sfha_set} />
        </div>
        {children}
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '70px', //width: '300px',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer'
  }
};

export default Safahat;