import React, { useEffect } from 'react'
//import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom"
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import Home from "./pages/Home"
import ExplorSura from "./pages/ExplorSura"
import ExplorSafha from "./pages/ExplorSafha"
import ExplorRiwaya from './pages/ExplorRiwaya'
import Search from "./pages/Search"
import Duaa from "./pages/Duaa"
import Study from './pages/Study'
import NoPage from "./pages/NoPage"
import Login from "./pages/Login"

import './styles/mystyle.css'
import './styles/mainmenu.css'

function App() {
  
  const handleload = () => { 
    console.log("start");
  };

  const handleSave = () => { 
    console.log("stop");
  };

  useEffect(() => { 
    handleload();
    window.addEventListener('beforeunload', handleSave); // Listen for page unload
    return (() => {
      window.removeEventListener('beforeunload', handleSave); // Cleanup the event listener on component unmount
    });
  }, []);

  const Navig = () => {
    return (
      <div>
        {/*
         <nav className='row'>
          <ul>
            <li> <Link to="/"              > مـــرحــبا    </Link> </li>
            <li> <Link to="/explor_sura"   > تـــصــفـــح  </Link> </li>
            <li> <Link to="/explor_safha"  > تـــصــفـــح  </Link> </li>
            <li> <Link to="/explor_riwaya" > تـــصــفـــح  </Link> </li>
            <li> <Link to="/search"        > بــحــث       </Link> </li>
            <li> <Link to="/duaa"          > دعـــــاء     </Link> </li>
            <li> <Link to="/study"         > study         </Link> </li>
            <li> <Link to="/riwayat"       > روايات        </Link> </li>
          </ul>
        </nav>
        */}
        <Outlet />
      </div>
    )

  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navig />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="home" element={<Home />} />
            <Route path="explor_sura" element={<ExplorSura />} />
            <Route path="explor_safha" element={<ExplorSafha />} />
            <Route path="explor_riwaya" element={<ExplorRiwaya />} />
            <Route path="search" element={<Search />} />
            <Route path="duaa" element={<Duaa />} />
            <Route path="study" element={<Study />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter >
    </div>
  );
}
export default App;


