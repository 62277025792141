import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMyContext } from "../component/MyContext";

function Main() {
   const { admn } = useMyContext();
   const navigate = useNavigate();

   useEffect(() => {
      document.documentElement.setAttribute('dir', 'rtl');
   }, []);

   function ExplorSura() {
      const handleClick = (e) => {
         navigate('/explor_sura');
      };
      return (
         <div>
            <button onClick={handleClick}
               style={{ width: 200, height: 100, fontSize: 20 }}
            >
               <label> عرض المصاحف  </label>
               <br /><br />
               <label> (حسب السور) </label>
            </button>
         </div>
      )
   }

   function ExplorSafha() {
      const handleClick = (e) => {
         navigate('/explor_safha');
      };
      return (
         <div>
            <button onClick={handleClick}
               style={{ width: 200, height: 100, fontSize: 20 }}
            >
               <label> عرض المصاحف </label>
               <br /><br />
               <label> (حسب الصفحات) </label>
            </button>
         </div>
      )
   }

   function ExplorRiwaya() {
      const handleClick = (e) => {
         navigate('/explor_riwaya');
      };
      return (
         <div>
            <button onClick={handleClick}
               style={{ width: 200, height: 100, fontSize: 20 }}
            >
               <label> عرض الروايات  </label>
               <br /><br />
               <label> (آية / آية) </label>
            </button>
         </div>
      )
   }

   function Search() {
      const handleClick = (e) => {
         navigate('/search');
      };
      return (
         <div>
            <button
               onClick={handleClick}
               style={{ width: 100, height: 100, fontSize: 10 }}
            >
               {"Search"}
            </button>
         </div>
      )
   }



   function Study() {
      const handleClick = (e) => {
         navigate('/study');
      };
      return (
         <div>
            <button onClick={handleClick}
               style={{ width: 100, height: 100, fontSize: 10 }}
            >
               {"Study"}
            </button>
         </div>
      )
   }

   function Duaa() {
      const handleClick = (e) => {
         navigate('/duaa');
      };
      return (
         <div>
            <button onClick={handleClick}
               style={{ width: 200, height: 100, fontSize: 20 }}
            >
               {"دعـــــاء"}
            </button>
         </div>
      )
   }

   if (admn === 0) // not admin
      return (
         <div className="col">
            <ExplorSura />
            <hr />
            <ExplorSafha />
            <hr />
            <ExplorRiwaya />
            <hr />
            <Duaa />
         </div>
      )
   else // is admin
      return (
         <div className="col">
            <ExplorSura />
            <hr />
            <ExplorSafha />
            <hr />
            <ExplorRiwaya />
            <hr />
            <Duaa />
            <hr />
            <Search />
            <hr />
            <Study />
         </div>)
};

export default Main;

