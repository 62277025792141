import React from 'react';

const TableFromJasonFor = ({ title, head, data }) => {
  
  //console.log("data.length:",data.length);
  var headers;
  if (head) headers = head;
  else headers = Object.keys(data[0]);

  const heads = [];
  for (let i = 0; i < headers.length; i++) {
    heads.push(<th key={i}> {headers[i]} </th>);
  }
  const rows = []
  for (let i = 0; i < data.length; i++) {
    const items = data[i];
    const cols = []
    Object.keys(items).forEach(j => {
      cols.push(<td key={j}>{items[j]}</td>)
    })
    rows.push(<tr key={i}>{cols}</tr>);
  }
  return (
    <table>
      <caption> {title} </caption>
      <thead><tr>{heads}</tr></thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default TableFromJasonFor;